
import {
  apiDesignTask,
  apiDesignTaskDelete,
  apiDesignTaskReset,
} from "@/api/app";

import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import { apiShopLists } from "@/api/shop";
import DatePicker from "@/components/date-picker.vue";

@Component({
  components: {
    lsPagination,
    DatePicker,
  },
})
export default class DesignTaskList extends Vue {
  /** S Data **/
  searchObj: any = {
    id: "",
    shop_sn: "",
    target_type: 2,
    // 获取今日日期
    create_time_start: "",
    // 获取明日日期
    create_time_end: "",
    page_no: 1,
    page_size: 50,
    status: -10,
  };
  lists: any = [];
  total = 0;
  selectIds: any = [];
  shopList: any = [];
  shops = {};
  targetTypeList: any = [
    {
      label: "商户后台设计",
      value: 1,
    },
    {
      label: "用户设计",
      value: 2,
    },
    {
      label: "用户设计合成",
      value: 3,
    },
    {
      label: "生产图",
      value: 4,
    },
    {
      label: "规格图片",
      value: 5,
    },
  ];
  statusList: any = [
    { label: "全部", value: -10 },
    { label: "待制作", value: 0 },
    { label: "制作中", value: 1 },
    { label: "制作完成", value: 2 },
  ];
  /** E Data **/
  apiDesignTask = apiDesignTask;

  pager: RequestPaging = new RequestPaging({ size: 50 });

  getList() {
    apiDesignTask(this.searchObj).then((res) => {
      this.lists = res.lists;
      this.total = res.count;
    });
  }
  getStusLabel(status: number) {
    const item = this.statusList.find((item: any) => item.value == status);
    return item ? item.label : "";
  }
  handleSelectionChange(val: any) {
    this.selectIds = val.map((item: any) => item.id);
  }
  handleReset(id: any) {
    const ids = id ? [id] : this.selectIds;
    this.$confirm("是否确定重置?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      apiDesignTaskReset({ ids }).then(() => {
        this.getList();
      });
    });
  }
  handleDelete(id: any) {
    const ids = id ? [id] : this.selectIds;
    this.$confirm("是否确定删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      apiDesignTaskDelete({ ids }).then(() => {
        this.getList();
      });
    });
  }
  resetSearch() {
    const refs = this.$refs.form as HTMLFormElement;
    refs.resetFields();
    this.getList();
  }
  getShopList() {
    apiShopLists({
      page: 1,
      page_size: 10000,
    }).then((res) => {
      this.shopList = res.lists;
      let list: any = {};
      for (let item of res.lists) {
        list[item.sn] = item.name;
      }
      this.shops = list;
    });
  }
  currentChange(val: any) {
    this.searchObj.page_no = val;
    this.getList();
  }

  /** E Methods **/

  /** S ods **/
  created() {
    // this.searchObj.create_time_start = new Date().toISOString().split("T")[0];
    // this.searchObj.create_time_end = new Date(
    //   new Date().getTime() + 24 * 60 * 60 * 1000
    // )
    //   .toISOString()
    //   .split("T")[0];
    this.getList();
    this.getShopList();
  }
}
